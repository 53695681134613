export default {
  breakpoints: ['40em', '52em', '64em'],
  fontSizes: [14, 16, 20, 24, 32, 48, 64],
  colors: {
    brand: '#343a40',
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256],
  fonts: {
    body: "'Montserrat', sans-serif",
    heading: "'Coolvetica Rg', sans-serif",
  },
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25,
  },
  shadows: {
    small: '0 0 6px 0 rgba(0, 0, 0, 0.16)',
    large: '0 0 70px 0 rgba(0, 0, 0, 0.16)',
  },
  forms: {},
  variants: {
    button: {
      color: 'white',
      backgroundColor: 'brand',
      borderWidth: 0,
      borderRadius: 20,
      fontSize: 2,
      fontFamily: 'heading',
      textTransform: 'lowercase',
      paddingX: 4,
      paddingY: 2,
      display: 'inline-block',
      textDecoration: 'none',
      ':disabled': {
        opacity: 0.7,
      },
    },
  },
  text: {},
  buttons: {
    primary: {
      color: 'white',
      backgroundColor: 'brand',
      borderWidth: 0,
      borderRadius: 20,
      fontSize: 2,
      fontFamily: 'heading',
      textTransform: 'lowercase',
      paddingX: 4,
      ':hover': {
        cursor: 'pointer',
      },
      ':disabled': {
        opacity: 0.7,
      },
    },
    link: {
      color: 'black',
      backgroundColor: 'transparent',
      border: 0,
      fontFamily: 'body',
      ':hover': {
        cursor: 'pointer',
      },
      ':disabled': {
        opacity: 0.7,
      },
    },
  },
};
