import * as Sentry from '@sentry/browser';
import { ApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client';
import { RewriteFrames } from '@sentry/integrations';
import getConfig from 'next/config';
import 'photoswipe/dist/photoswipe.css';
import 'photoswipe/dist/default-skin/default-skin.css';

import '../i18n';
import Layout from 'components/layout';
import CartProvider from 'components/cartprovider';

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  const config = getConfig();
  const distDir = `${config.serverRuntimeConfig.rootDir}/.next`;
  Sentry.init({
    enabled: process.env.NODE_ENV === 'production',
    integrations: [
      new RewriteFrames({
        iteratee: (frame) => {
          frame.filename = frame.filename.replace(distDir, 'app:///_next');
          return frame;
        },
      }),
    ],
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    environment: process.env.NEXT_PUBLIC_ENVIRONMENT,
  });
}

const client = new ApolloClient({
  uri: '/api/graphql',
  cache: new InMemoryCache(),
});

// err => Workaround for https://github.com/vercel/next.js/issues/8592
export default function App({ Component, pageProps, err }) {
  return (
    <ApolloProvider client={client}>
      <CartProvider>
        <Layout>
          <Component {...pageProps} err={err} />
        </Layout>
      </CartProvider>
    </ApolloProvider>
  );
}
